@import url("https://use.typekit.net/trt3qfc.css");
@import url("https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css");

* {
    box-sizing: border-box;
}

body {
    overscroll-behavior-y: none;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "inter-variable", sans-serif;
}

html {
    overscroll-behavior-y: none;
    font-size: 16px;
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.6rem;
}

h3 {
    font-size: 2.2rem;
    font-weight: 500;
}

h4 {
    font-size: 1.8rem;
    font-weight: 500;
}

h5 {
    font-size: 1.4rem;
    font-weight: 500;
}

h6 {
    font-size: 1rem;
    font-weight: 500;
}

p {
    font-size: 1rem;
}

@media (max-width: 1024px) {
    .desktop-only {
        display: none;
    }
}
